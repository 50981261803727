import Toggle from '@components/forms/components/Toggle'
import { RECALCULATION_STATUSES, type CompanySchema } from '@pages/index'
import { useTranslation } from 'next-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import ReactSelect from 'react-select'
import { Divider } from 'rsuite'
import { selectFieldOptionStyles, selectFieldStyles } from 'styles/form'

const FeaturesFields = () => {
  const { t } = useTranslation()

  const { control, watch, formState } = useFormContext<CompanySchema>()

  const [watchSimplifiedOrders, watchOrdersCostRecalculationEnabled] = watch([
    'simplifiedOrders',
    'ordersCostRecalculation.enabled',
    'acquiring',
    'fiscalization',
  ])

  return (
    <>
      <Toggle
        control={control}
        name="voipAvailable"
        label={t('pages.home.form.voip')}
        className="mt-10 mb-5"
      />

      <Divider />

      <Toggle
        control={control}
        name="proofDelivery"
        label={t('pages.home.form.proof_delivery')}
        className="mb-5"
      />

      <Divider />

      <Toggle
        control={control}
        name="sendArrivedEvent"
        label={t('pages.home.form.send_arrived_event')}
        className="mb-5"
      />

      <Divider />

      <Toggle
        control={control}
        name="sendRouteFinishedEvent"
        label={t('pages.home.form.send_route_finished_event')}
        className="mb-5"
      />

      <Divider />

      <Toggle
        control={control}
        name="financesEnabled"
        label={t('pages.home.form.finances_enabled')}
        className="mb-5"
      />

      <Divider />

      <Toggle
        control={control}
        name="assignCoDriverForRoutes"
        label={t('pages.home.form.assign_co_driver_for_routes')}
        className="mb-5"
      />

      <Divider />

      {watchSimplifiedOrders ? null : (
        <>
          <Toggle
            control={control}
            name="strictWaresCheck"
            label={t('pages.home.form.strict_wares_check')}
            className="mb-5"
          />

          <Divider />
        </>
      )}

      {watchSimplifiedOrders ? null : (
        <>
          <Toggle
            control={control}
            name="hideWaresCost"
            label={t('pages.home.form.hide_wares_cost')}
            className="mb-5"
          />

          <Divider />
        </>
      )}

      {watchSimplifiedOrders ? null : (
        <>
          <Toggle
            control={control}
            name="ordersCostRecalculation.enabled"
            label={t('pages.home.form.orders_cost_recalculation')}
            className="mb-5"
          />

          {watchOrdersCostRecalculationEnabled && (
            <>
              <div className="form-control w-[calc(50%-6px)]">
                <label className="label-text-alt mb-2">
                  {t('pages.home.form.orders_cost_recalculation_statuses')}
                </label>

                <Controller
                  name="ordersCostRecalculation.statuses"
                  control={control}
                  render={({ field: { value, onChange, onBlur } }) => {
                    return (
                      <ReactSelect
                        value={value.map(status => ({
                          value: status,
                          label: t(`orders.status.${status}`),
                        }))}
                        options={RECALCULATION_STATUSES.map(status => ({
                          label: t(`orders.status.${status}`),
                          value: status,
                        }))}
                        placeholder={t('pages.home.form.choose_statuses')}
                        isMulti
                        onChange={options =>
                          onChange(
                            options.map(
                              option =>
                                (option as unknown as { value: string }).value,
                            ),
                          )
                        }
                        onBlur={onBlur}
                        theme={selectFieldOptionStyles}
                        styles={selectFieldStyles}
                      />
                    )
                  }}
                />
                {formState.errors.ordersCostRecalculation?.statuses?.length! >
                  0 && (
                  <label className="label">
                    <span className="label-text-alt text-red-500">
                      {
                        formState.errors.ordersCostRecalculation?.statuses?.[0]
                          .message
                      }
                    </span>
                  </label>
                )}
              </div>
            </>
          )}

          <Divider />
        </>
      )}
    </>
  )
}

export default FeaturesFields
