import { Table as ReactTable, flexRender } from '@tanstack/react-table'
import { classnames } from '@tools/common'

interface TableProps<T> {
  table: ReactTable<T>
  rowRef?: React.Ref<HTMLTableRowElement>
  onClickRow?: (row: T) => void
}

const Table = <T extends object>({
  table,
  rowRef,
  onClickRow,
}: TableProps<T>) => {
  const headers = table.getFlatHeaders()
  const rows = table.getRowModel().rows

  return (
    <div>
      <table className="table w-full">
        <thead className="sticky top-0 z-20">
          <tr>
            {headers.map(header => {
              return (
                <th
                  key={header.id}
                  className={classnames('relative', {
                    'sticky z-10':
                      typeof header.column.getIsPinned() === 'string',
                    'left-0': header.column.getIsPinned() === 'left',
                    'right-0': header.column.getIsPinned() === 'right',
                  })}
                  style={{
                    minWidth: header.getSize(),
                    width: header.getSize(),
                  }}
                >
                  {header.isPlaceholder ? null : (
                    <span>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                    </span>
                  )}
                  {header.column.getCanResize() && (
                    <div
                      onMouseDown={header.getResizeHandler()}
                      onTouchStart={header.getResizeHandler()}
                      className={`resizer ${
                        header.column.getIsResizing() ? 'isResizing' : ''
                      }`}
                    />
                  )}
                </th>
              )
            })}
          </tr>
        </thead>

        <tbody>
          {rows.map(row => (
            <tr
              key={row.id}
              ref={rowRef}
              className="hover cursor-pointer"
              onClick={() => {
                if (onClickRow) {
                  onClickRow(row.original)
                }
              }}
            >
              {row.getVisibleCells().map((cell, index) => {
                return index === 0 ? (
                  <th
                    key={cell.id}
                    className={classnames({
                      'sticky z-10':
                        typeof cell.column.getIsPinned() === 'string',
                      'left-0': cell.column.getIsPinned() === 'left',
                      'right-0': cell.column.getIsPinned() === 'right',
                    })}
                    style={{ width: cell.column.getSize() }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </th>
                ) : (
                  <td
                    key={cell.id}
                    className={classnames({
                      'sticky z-10':
                        typeof cell.column.getIsPinned() === 'string',
                      'left-0': cell.column.getIsPinned() === 'left',
                      'right-0': cell.column.getIsPinned() === 'right',
                    })}
                    style={{ width: cell.column.getSize() }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                )
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default Table
