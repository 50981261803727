import type { Company } from '@models/company'
import EditIcon from '@rsuite/icons/legacy/Edit2'
import { createColumnHelper } from '@tanstack/react-table'
import { getYesNo } from '@tools/common'
import { useTranslation } from 'next-i18next'
import { IconButton } from 'rsuite'

interface UseCompanyColumnDefsProps {
  onClickEdit: (companyId: string) => void
}

const useCompanyColumnDefs = ({ onClickEdit }: UseCompanyColumnDefsProps) => {
  const { t } = useTranslation()

  const columnHelper = createColumnHelper<Company>()

  const columnDefs = [
    columnHelper.accessor(row => row.name, {
      id: 'name',
      cell: info => info.getValue(),
      header: () => t('pages.home.table.name'),
    }),
    columnHelper.accessor(row => row.companyExternalId, {
      id: 'id',
      cell: info => info.getValue(),
      header: () => 'ID',
    }),
    columnHelper.accessor(row => row.countryCode, {
      id: 'countryCode',
      cell: info => info.getValue(),
      header: () => t('pages.home.table.country_code'),
    }),
    columnHelper.accessor(row => row.domen, {
      id: 'domen',
      cell: info => info.getValue(),
      header: () => t('pages.home.table.domen'),
    }),
    columnHelper.accessor(row => row.voipAvailable, {
      id: 'voip',
      cell: info => <p className="text-center">{getYesNo(info.getValue())}</p>,
      header: () => <p className="text-center">{t('pages.home.table.voip')}</p>,
    }),
    columnHelper.accessor(row => row.simplifiedOrders, {
      id: 'simplifiedOrders',
      cell: info => <p className="text-center">{getYesNo(info.getValue())}</p>,
      header: () => (
        <p className="text-center">{t('pages.home.table.simplified_orders')}</p>
      ),
    }),
    columnHelper.accessor(row => row.proofDelivery, {
      id: 'proofDelivery',
      cell: info => <p className="text-center">{getYesNo(info.getValue())}</p>,
      header: () => (
        <p className="text-center">{t('pages.home.table.proof_delivery')}</p>
      ),
    }),
    columnHelper.accessor(row => row, {
      id: 'editCompany',
      size: 50,
      cell: cell => (
        <IconButton
          onClick={e => {
            e.stopPropagation()
            onClickEdit(cell.getValue().id)
          }}
          icon={<EditIcon className="h-4 w-4" />}
          appearance="subtle"
          circle
        />
      ),
      header: () => <span />,
      enableResizing: false,
    }),
  ]

  return columnDefs
}

export default useCompanyColumnDefs
