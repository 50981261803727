import Input from '@components/forms/components/Input'
import Select from '@components/forms/components/Select'
import type { CompanySchema } from '@pages/index'
import { useTranslation } from 'next-i18next'
import { useFormContext } from 'react-hook-form'

const CONFIG_TYPES = ['none', 'http', 'rabitmq'] as const

const ConfigFields = () => {
  const { t } = useTranslation()

  const { control, formState, watch } = useFormContext<CompanySchema>()

  const watchConfigType = watch('config.type')

  return (
    <div className="flex flex-wrap gap-3">
      <Select
        data={CONFIG_TYPES.map(type => ({
          label: type,
          value: type,
        }))}
        label={t('pages.home.form.config_type')}
        control={control}
        name="config.type"
        error={formState.errors.config?.type.message}
        className="w-[calc(50%-6px)]"
      />

      {watchConfigType === 'http' && (
        <Input
          control={control}
          name="config.url"
          label={t('pages.home.form.config_url')}
          error={formState.errors.config?.url.message}
          className="w-[calc(50%-6px)]"
        />
      )}

      {watchConfigType === 'rabitmq' && (
        <>
          <Input
            control={control}
            name="config.headFrom"
            label={t('pages.home.form.config_head_from')}
            error={formState.errors.config?.headFrom.message}
            className="w-[calc(50%-6px)]"
          />

          <Input
            control={control}
            name="config.headTo"
            label={t('pages.home.form.config_head_to')}
            error={formState.errors.config?.headTo.message}
            className="w-[calc(50%-6px)]"
          />

          <Input
            control={control}
            name="config.exchange"
            label={t('pages.home.form.config_exchange')}
            error={formState.errors.config?.exchange.message}
            className="w-[calc(50%-6px)]"
          />

          <Input
            control={control}
            name="config.routingKey"
            label={t('pages.home.form.config_routing_key')}
            error={formState.errors.config?.routingKey.message}
            className="w-[calc(50%-6px)]"
          />

          <Input
            control={control}
            name="config.hostname"
            label={t('pages.home.form.config_hostname')}
            error={formState.errors.config?.hostName.message}
            className="w-[calc(50%-6px)]"
          />

          <Input
            control={control}
            name="config.port"
            label={t('pages.home.form.config_port')}
            error={formState.errors.config?.port.message}
            className="w-[calc(50%-6px)]"
          />

          <Input
            control={control}
            name="config.username"
            label={t('pages.home.form.config_username')}
            error={formState.errors.config?.username.message}
            className="w-[calc(50%-6px)]"
          />

          <Input
            control={control}
            name="config.password"
            label={t('pages.home.form.config_password')}
            error={formState.errors.config?.password.message}
            className="w-[calc(50%-6px)]"
          />

          <Input
            control={control}
            name="config.vhost"
            label={t('pages.home.form.config_vhost')}
            error={formState.errors.config?.vhost.message}
            className="w-[calc(50%-6px)]"
          />
        </>
      )}
    </div>
  )
}

export default ConfigFields
