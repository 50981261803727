import type { Theme } from 'react-select'

export const selectFieldOptionStyles = (theme: Theme) => ({
  ...theme,
  borderRadius: 8,
  spacing: { ...theme.spacing, baseUnit: 6.5 },
  colors: {
    ...theme.colors,
    primary: '#d2d4d7',
    primary25: '#d2d4d7',
    primary50: 'transparent',
    primary75: 'transparent',
  },
})

export const selectFieldStyles = {
  control: (base: any) => ({
    ...base,
    boxShadow: 'none',
  }),
}
