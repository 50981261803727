import { classnames } from '@tools/common'
import { omit } from 'lodash'
import { useTranslation } from 'next-i18next'
import { FieldValues, UseControllerProps, useController } from 'react-hook-form'

export interface SelectData {
  label: string
  value: string
}

export interface SelectProps<T extends FieldValues>
  extends UseControllerProps<T> {
  data: SelectData[]
  label?: string | JSX.Element
  error?: string
  selectProps?: React.SelectHTMLAttributes<HTMLSelectElement>
  className?: string
  onChangeValue?: (value: string) => string
}

const Select = <T extends FieldValues>({
  data,
  label,
  control,
  name,
  error,
  selectProps,
  className,
  onChangeValue,
}: SelectProps<T>) => {
  const { t } = useTranslation()

  const { field } = useController({ name, control })

  return (
    <div className={classnames('form-control', className)}>
      {label && (
        <>
          {typeof label === 'string' ? (
            <label className="label">
              <span className="label-text">{label}</span>
            </label>
          ) : (
            label
          )}
        </>
      )}
      <select
        {...omit(field, 'onSelect')}
        onChange={value =>
          field.onChange(onChangeValue ? onChangeValue(value as any) : value)
        }
        className={classnames(
          'select select-bordered focus:ring-0 focus:border-gray-300 w-full',
          {
            'select-error': error && error.length > 0,
          },
          selectProps?.className,
        )}
        {...omit(selectProps, ['className'])}
      >
        <option onClick={() => field.onChange(null)} value="">
          {t('ui.not_selected')}
        </option>
        {data.map(item => (
          <option key={item.value} label={item.label} value={item.value} />
        ))}
      </select>
      {error && error.length > 0 && (
        <label className="label">
          <span className="label-text-alt text-red-500">{error}</span>
        </label>
      )}
    </div>
  )
}

export default Select
