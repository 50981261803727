import { classnames } from '@tools/common'
import { omit } from 'lodash'
import { FieldValues, UseControllerProps, useController } from 'react-hook-form'

export interface InputProps<T extends FieldValues>
  extends UseControllerProps<T> {
  label?: string | JSX.Element
  error?: string
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>
  className?: string
}

const Input = <T extends FieldValues>({
  label,
  control,
  name,
  error,
  inputProps,
  className,
}: InputProps<T>) => {
  const {
    field: { value, ...restFields },
  } = useController({ name, control })

  return (
    <div className={classnames('form-control', className)}>
      {label && (
        <>
          {typeof label === 'string' ? (
            <label className="label">
              <span className="label-text">{label}</span>
            </label>
          ) : (
            label
          )}
        </>
      )}
      <input
        {...restFields}
        value={value ?? ''}
        className={classnames(
          'focus:ring-0',
          {
            'w-full input input-bordered input-md focus:border-gray-300':
              inputProps?.type !== 'checkbox',
            'checkbox checkbox-primary checkbox-md':
              inputProps?.type === 'checkbox',
            'checkbox-error':
              error && error.length > 0 && inputProps?.type === 'checkbox',
            'input-error': error && error.length > 0,
            'input-disabled': inputProps?.disabled,
          },
          inputProps?.className,
        )}
        {...omit(inputProps, ['className'])}
      />
      {error && error.length > 0 && (
        <label className="label">
          <span className="label-text-alt text-red-500">{error}</span>
        </label>
      )}
    </div>
  )
}

export default Input
