import { useAuth, useCompanies, useCompaniesMutations } from '@api'
import { Loader } from '@components'
import Layout from '@components/Layout'
import ConfigFields from '@components/forms/companies/ConfigFields'
import FeaturesFields from '@components/forms/companies/FeaturesFields'
import GeneralFields from '@components/forms/companies/GeneralFields'
import CompaniesTable from '@components/tables/CompaniesTable'
import { zodResolver } from '@hookform/resolvers/zod'
import type { Company } from '@models/company'
import { stringToArrayField } from '@tools/common'
import type { GetServerSideProps } from 'next'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Modal, Nav, NavProps } from 'rsuite'
import z from 'zod'

export const ACQUIRING_PROVIDERS = ['none', 'mono'] as const
export const FISCALIZATION_PROVIDERS = ['none', 'checkbox'] as const
export const RECALCULATION_STATUSES = [
  'PARTIAL_RETURN',
  'NOT_DELIVERED',
] as const

const schema = z.object({
  id: z.string().optional(),
  companyExternalId: z.string().min(1),
  name: z.string().min(1),
  apiKey: z.string().optional(),
  countryCode: z.string().min(1),
  domen: z.string().min(1),
  supportPhone: z.string().min(1),
  wsVersion: z.string(),
  returnOrderReasons: z.string().or(z.array(z.string())),
  returnWareReasons: z.string().or(z.array(z.string())),
  rejectEcmrReasons: z.string().or(z.array(z.string())).optional(),
  voipAvailable: z.boolean(),
  sendArrivedEvent: z.boolean(),
  sendRouteFinishedEvent: z.boolean(),
  simplifiedOrders: z.boolean(),
  proofDelivery: z.boolean(),
  financesEnabled: z.boolean(),
  strictWaresCheck: z.boolean(),
  assignCoDriverForRoutes: z.boolean(),
  hideWaresCost: z.boolean(),
  ordersCostRecalculation: z
    .object({
      enabled: z.boolean(),
      statuses: z.array(z.enum(RECALCULATION_STATUSES)),
    })
    .refine(
      val => {
        if (!val.enabled) {
          return true
        }

        return val.statuses.length > 0
      },
      {
        message: 'Statuses must not be empty if enabled',
        path: ['statuses[0]'],
      },
    ),
  acquiring: z
    .object({
      enabled: z.boolean(),
      provider: z.enum(ACQUIRING_PROVIDERS),
      config: z.object({
        token: z.string().or(z.null()).optional(),
      }),
    })
    .refine(
      val => {
        return val.enabled && val.provider === 'mono'
          ? !!val.config.token
          : true
      },
      {
        message: 'Token is required for mono provider',
        path: ['config.token'],
      },
    ),
  fiscalization: z.object({
    enabled: z.boolean(),
    provider: z.enum(FISCALIZATION_PROVIDERS),
    config: z.object({
      token: z.string().or(z.null()).optional(),
    }),
  }),
  config: z.any(),
})

export type CompanySchema = z.infer<typeof schema>

const DEFAULT_WS_VERSION = '8'

interface CompanyFormProps {
  defaultValues?: CompanySchema
  closeModal: () => void
}

type NavKey = 'general' | 'config' | 'features'

const Navbar = ({ activeKey, onSelect, ...props }: NavProps) => {
  const { t } = useTranslation()

  return (
    <Nav {...props} activeKey={activeKey} onSelect={onSelect}>
      <Nav.Item eventKey="general">{t('pages.home.form.general')}</Nav.Item>
      <Nav.Item eventKey="config">{t('pages.home.form.erp_config')}</Nav.Item>
      <Nav.Item eventKey="features">{t('pages.home.form.features')}</Nav.Item>
    </Nav>
  )
}

interface FormFieldsProps {
  navKeyActive: NavKey
  isEdit: boolean
}

const FormFields = ({ navKeyActive, isEdit }: FormFieldsProps) => {
  if (navKeyActive === 'config') {
    return <ConfigFields />
  }

  if (navKeyActive === 'features') {
    return <FeaturesFields />
  }

  return <GeneralFields isEdit={isEdit} />
}

const CompanyForm = ({ defaultValues, closeModal }: CompanyFormProps) => {
  const [navKeyActive, setNavKeyActive] = useState<NavKey>('general')

  const companiesMutations = useCompaniesMutations()

  const methods = useForm<CompanySchema>({
    resolver: zodResolver(schema),
    defaultValues: {
      voipAvailable: false,
      simplifiedOrders: false,
      sendRouteFinishedEvent: false,
      proofDelivery: false,
      financesEnabled: false,
      sendArrivedEvent: false,
      strictWaresCheck: true,
      assignCoDriverForRoutes: false,
      hideWaresCost: false,
      config: { type: 'none' },
      acquiring: {
        enabled: false,
        provider: 'none',
        config: {
          token: null,
        },
      },
      fiscalization: {
        enabled: false,
        provider: 'none',
        config: {
          token: null,
        },
      },
      ordersCostRecalculation: {
        enabled: false,
        statuses: [],
      },
      ...defaultValues,
    },
  })

  const { handleSubmit } = methods

  const onSubmit = handleSubmit(
    ({
      returnOrderReasons,
      returnWareReasons,
      apiKey,
      wsVersion,
      rejectEcmrReasons,
      ...restData
    }) => {
      const company: Partial<Company> = {
        ...restData,
        wsVersion: +(wsVersion ?? DEFAULT_WS_VERSION),
        returnOrderReasons: stringToArrayField(returnOrderReasons),
        returnWareReasons: stringToArrayField(returnWareReasons),
        ...(!!rejectEcmrReasons && {
          rejectEcmrReasons: stringToArrayField(rejectEcmrReasons),
        }),
      }

      if (defaultValues) {
        companiesMutations.updateCompany.mutate(company)
      } else {
        companiesMutations.createCompany.mutate(company)
      }

      closeModal()
    },
  )

  return (
    <form onSubmit={onSubmit} id="companies-form" className="px-2">
      <Navbar
        className="sticky top-0 bg-white mb-5"
        appearance="subtle"
        activeKey={navKeyActive}
        onSelect={setNavKeyActive}
      />

      <FormProvider {...methods}>
        <FormFields navKeyActive={navKeyActive} isEdit={!!defaultValues?.id} />
      </FormProvider>
    </form>
  )
}

const HomePage = () => {
  const { t } = useTranslation()

  const router = useRouter()

  const companiesQuery = useCompanies()
  const authQuery = useAuth()

  const [modalOpened, setModalOpened] = useState(false)
  const [selectedId, setSelectedId] = useState<string | undefined>()

  const onOpenModal = (id?: string) => {
    setSelectedId(id)
    setModalOpened(true)
  }

  const onCloseModal = () => {
    setModalOpened(false)
    router.replace(router.asPath, { query: {} })
    setTimeout(() => {
      setSelectedId(undefined)
    }, 500)
  }

  if (!companiesQuery.isSuccess || !authQuery.isSuccess) {
    return <Loader queries={[companiesQuery, authQuery]} />
  }

  const currCompany = companiesQuery.data.companiesMap.get(selectedId ?? '')

  const onClickRow = (companyId: string) => {
    localStorage.setItem('companyId', companyId)
    router.push(`/companies/${companyId}/orders`)
  }

  return (
    <Layout title={t('pages.home.title')}>
      <div className="overflow-auto max-h-[calc(100vh-183px)] rounded-lg">
        <CompaniesTable
          data={companiesQuery.data.companies}
          onClickRow={onClickRow}
          onClickEdit={onOpenModal}
        />
      </div>

      <button
        className="btn w-40 btn-primary absolute right-5 bottom-5"
        onClick={() => onOpenModal()}
      >
        {t('ui.create')}
      </button>

      <Modal
        open={modalOpened}
        onClose={onCloseModal}
        backdrop="static"
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>
            {selectedId
              ? `${t('ui.edit')} ${currCompany!.companyExternalId}`
              : t('ui.create')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CompanyForm
            closeModal={onCloseModal}
            defaultValues={
              currCompany
                ? {
                    ...currCompany,
                    wsVersion:
                      currCompany?.wsVersion?.toString() ?? DEFAULT_WS_VERSION,
                  }
                : undefined
            }
          />
        </Modal.Body>
        <Modal.Footer>
          <button form="companies-form" className="w-40 btn btn-primary">
            {t('ui.submit')}
          </button>
        </Modal.Footer>
      </Modal>
    </Layout>
  )
}

export default HomePage

export const getServerSideProps: GetServerSideProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale!)),
    },
  }
}
