import Input from '@components/forms/components/Input'
import Select from '@components/forms/components/Select'
import type { CompanySchema } from '@pages/index'
import { useTranslation } from 'next-i18next'
import { useFormContext } from 'react-hook-form'
import Textarea from '@components/forms/components/Textarea'
import Toggle from '@components/forms/components/Toggle'

const COUNTRY_CODES = ['UA', 'RUS', 'KZ', 'LV', 'CS', 'FR', 'GE'] as const
const WS_VERSIONS = ['8', '10', '11'] as const

interface GeneralFieldsProps {
  isEdit: boolean
}

const GeneralFields = ({ isEdit }: GeneralFieldsProps) => {
  const { t } = useTranslation()

  const { control, formState, watch } = useFormContext<CompanySchema>()

  const watchSimplifiedOrders = watch('simplifiedOrders')

  return (
    <>
      <div className="flex flex-wrap gap-3">
        <Input
          control={control}
          name="companyExternalId"
          label="ID"
          error={formState.errors.companyExternalId?.message}
          inputProps={{ disabled: isEdit }}
          className="w-[calc(50%-6px)]"
        />
        <Input
          control={control}
          name="name"
          label={t('pages.home.form.name')}
          error={formState.errors.name?.message}
          className="w-[calc(50%-6px)]"
        />
        <Input
          control={control}
          name="apiKey"
          label={t('pages.home.form.api_key')}
          error={formState.errors.apiKey?.message}
          inputProps={{ disabled: true }}
          className="w-[calc(50%-6px)]"
        />
        <Select
          data={COUNTRY_CODES.map(code => ({
            label: code,
            value: code,
          }))}
          label={t('pages.home.form.country_code')}
          control={control}
          name="countryCode"
          error={formState.errors.countryCode?.message}
          className="w-[calc(50%-6px)]"
        />

        <Input
          control={control}
          name="domen"
          label={t('pages.home.form.domen')}
          error={formState.errors.domen?.message}
          className="w-[calc(50%-6px)]"
        />

        <Input
          control={control}
          name="supportPhone"
          label={t('pages.home.form.support_phone')}
          error={formState.errors.supportPhone?.message}
          className="w-[calc(50%-6px)]"
        />

        <Select
          data={WS_VERSIONS.map(version => ({
            label: version,
            value: version,
          }))}
          label={t('pages.home.form.ws_verion')}
          control={control}
          name="wsVersion"
          error={formState.errors.wsVersion?.message}
          className="w-[calc(50%-6px)]"
        />

        <div className="w-[calc(50%-6px)]" />

        <Textarea
          control={control}
          name="returnOrderReasons"
          label={t('pages.home.form.return_order_reasons')}
          textareaProps={{ className: 'max-h-[120px]' }}
          className="w-[calc(50%-6px)]"
          error={
            Array.isArray(formState.errors.returnOrderReasons)
              ? formState.errors.returnOrderReasons[0]?.message
              : formState.errors.returnOrderReasons?.message
          }
        />

        {watchSimplifiedOrders ? null : (
          <Textarea
            control={control}
            name="returnWareReasons"
            label={t('pages.home.form.return_ware_reasons')}
            textareaProps={{ className: 'max-h-[120px]' }}
            className="w-[calc(50%-6px)]"
            error={
              Array.isArray(formState.errors.returnWareReasons)
                ? formState.errors.returnWareReasons[0]?.message
                : formState.errors.returnWareReasons?.message
            }
          />
        )}

        {watchSimplifiedOrders ? null : (
          <Textarea
            control={control}
            name="rejectEcmrReasons"
            label={t('pages.home.form.reject_ecmr_reasons')}
            textareaProps={{ className: 'max-h-[120px]' }}
            className="w-[calc(50%-6px)]"
            error={
              Array.isArray(formState.errors.rejectEcmrReasons)
                ? formState.errors.rejectEcmrReasons[0]?.message
                : formState.errors.rejectEcmrReasons?.message
            }
          />
        )}
      </div>

      <div className="sticky bottom-0">
        <Toggle
          control={control}
          name="simplifiedOrders"
          label={t('pages.home.form.simplified_orders')}
          className="py-5 relative -bottom-5 bg-white"
        />
      </div>
    </>
  )
}

export default GeneralFields
