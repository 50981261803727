import { classnames } from '@tools/common'
import { FieldValues, UseControllerProps, useController } from 'react-hook-form'
import { Toggle as ToggleBase } from 'rsuite'

export interface ToggleProps<T extends FieldValues>
  extends UseControllerProps<T> {
  label?: string
  toggleProps?: React.InputHTMLAttributes<HTMLInputElement>
  className?: string
}

const Toggle = <T extends FieldValues>({
  label,
  control,
  name,
  className,
}: ToggleProps<T>) => {
  const { field } = useController({ name, control })

  return (
    <div
      className={classnames(
        'flex flex-row justify-between items-center',
        className,
      )}
    >
      {label && <label className="label-text">{label}</label>}

      <ToggleBase {...field} defaultChecked={field.value} />
    </div>
  )
}

export default Toggle
