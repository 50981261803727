import { classnames } from '@tools/common'
import { omit } from 'lodash'
import { FieldValues, UseControllerProps, useController } from 'react-hook-form'

export interface TextareaProps<T extends FieldValues>
  extends UseControllerProps<T> {
  label?: string
  error?: string
  textareaProps?: React.TextareaHTMLAttributes<HTMLTextAreaElement>
  className?: string
}

const Textarea = <T extends FieldValues>({
  label,
  control,
  name,
  error,
  textareaProps,
  className,
}: TextareaProps<T>) => {
  const { field } = useController({ name, control })

  return (
    <div className={classnames('form-control', className)}>
      {label && (
        <label className="label">
          <span className="label-text">{label}</span>
        </label>
      )}
      <textarea
        {...field}
        className={classnames(
          'w-full textarea textarea-bordered focus:border-gray-300 focus:ring-0',
          {
            'textarea-error': error && error.length > 0,
            'textarea-disabled': textareaProps?.disabled,
          },
          textareaProps?.className,
        )}
        {...omit(textareaProps, ['className'])}
      />
      {error && error.length > 0 && (
        <label className="label">
          <span className="label-text-alt text-red-500">{error}</span>
        </label>
      )}
    </div>
  )
}

export default Textarea
